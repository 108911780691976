.content-container {
  .overview {
    &__intro {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      border-bottom: 1px solid var(--light-gray);
      margin-bottom: 40px;
      padding-bottom: 30px;
      flex-direction: column;
      gap: 32px;

      @media only screen and (min-width: 1024px) {
        flex-direction: column;
        gap: 32px;
      }

      .paragraph {
        text-align: left;
        width: 100%;
        max-width: 780px;
        margin-right: auto;

        p {
          font-size: 1rem;
          line-height: 1.5;
          margin-top: 10px;
          margin-bottom: 0;
        }

        &__with-image {
          display: flex;
          flex-direction: column;
          max-width: 100%;
          position: relative;

          @media only screen and (min-width: 1305px) {
            flex-direction: row;
            min-height: 300px;
          }

          p {
            max-width: 780px;
          }

          img {
            width: 100%;
            max-width: 400px;
            border-radius: 20px;
            margin-top: 32px;

            @media only screen and (min-width: 1305px) {
              margin-top: 0;
              position: absolute;
              right: 0;
              top: 0;
              max-height: 300px;
            }
          }
        }
      }

      .filter-row {
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
        width: 100%;
        position: relative;
        z-index: 3;
        gap: 20px;

        .filter-column {
          width: 100%;

          &__margin-left {
            margin-left: auto;
          }

          @media only screen and (min-width: 1024px) {
            width: auto;
          }

          &--align-right {
            margin-left: auto;
          }

          .column-row {
            display: flex;
            gap: 20px;
            align-items: center;
            justify-content: flex-end;
            flex-direction: column;
            margin-top: 15px;

            @media only screen and (min-width: 1024px) {
              align-items: flex-start;

              &__right {
                align-items: flex-end;
              }
            }

            span {
              min-width: fit-content;
              display: block;

              @media screen and (min-width: 1024px) {
                margin-right: auto;
              }
            }

            .selected-answers {
              display: flex;
              justify-content: flex-end;
              width: 100%;
              margin-bottom: 12px;

              p {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                gap: 5px;
                margin: 0;
                margin-left: auto;

                .percentage {
                  font-family: var(--font-family-bold);
                }

                .number {
                  font-family: var(--font-family);
                  font-size: 0.6rem;
                }
              }
            }

            .scope-container {
              width: 100%;
              display: flex;
              justify-content: flex-end;
            }

            .scope-selection {
              display: flex;
              flex-wrap: wrap;
              width: 100%;
              border: 1px solid var(--light-gray-darker);
              border-radius: 4px;

              @media only screen and (min-width: 1024px) {
                width: auto;
              }

              > div {
                width: 100%;

                @media only screen and (min-width: 510px) {
                  flex-wrap: nowrap;
                }

                @media only screen and (min-width: 1024px) {
                  width: auto;
                }
              }

              .react-select,
              .react-select__transparent {
                &__control {
                  padding: 13px 12px 11px;
                  box-shadow: none;
                  cursor: pointer;
                  border: 0;
                  border-bottom: 1px solid var(--light-gray-darker);
                  border-radius: 0;

                  &:focus,
                  &:hover {
                    border: 0;
                    border-bottom: 1px solid var(--light-gray-darker);
                  }

                  @media only screen and (min-width: 1024px) {
                    border: 0;
                    border-right: 1px solid var(--light-gray-darker);
                    padding: 13px 12px 11px;

                    &:focus,
                    &:hover {
                      border: 0;
                      border-right: 1px solid var(--light-gray-darker);
                    }
                  }
                }

                &__indicator {
                  color: var(--black);
                  padding: 0;

                  &-separator {
                    display: none;
                  }
                }

                &__menu {
                  min-width: fit-content;
                  width: 100%;
                  z-index: 3;
                }

                &__value-container {
                  font-size: 1rem;
                  font-size: clamp(1rem, 0.975rem + 0.125vw, 1.125rem); // 16px to 18px

                  @media only screen and (min-width: 1024px) {
                    padding: 0 12px 0 0;
                  }
                }

                &__transparent {
                  &__control {
                    background: unset;
                    padding: 12px 0 12px 24px;
                  }
                }
              }

              .organisation {
                @media only screen and (min-width: 1024px) {
                  width: 180px;
                }
              }

              .department {
                @media only screen and (min-width: 1024px) {
                  width: 150px;
                }
              }

              .team {
                .react-select,
                .react-select__transparent {
                  &__control {
                    border: 0;

                    @media only screen and (min-width: 1024px) {
                      border: 0;
                    }
                  }
                }

                @media only screen and (min-width: 1024px) {
                  width: 120px;
                }
              }
            }
          }

          .date-selectors {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 12px;
            flex-wrap: wrap;
            flex-direction: column;
            width: 100%;

            span {
              flex: 1;

              @media only screen and (min-width: 1024px) {
                margin-left: 12px;
                text-align: right;
              }
            }

            @media only screen and (min-width: 1024px) {
              flex-direction: row;
              gap: 0;
            }

            .react-datepicker-wrapper {
              width: 100%;

              @media only screen and (min-width: 1024px) {
                width: auto;
                max-width: 160px;
                margin-left: 12px;
              }

              .react-datepicker__input-container {
                input {
                  width: calc(100% - 32px);
                  text-align: center;
                  padding: 14px 16px;
                  cursor: pointer;
                  font-family: var(--font-family);
                  font-size: 1rem;
                  border: 1px solid var(--light-gray-darker);
                  border-radius: 4px;

                  &:focus {
                    outline: none;
                  }
                }
              }
            }
          }
        }
      }
    }

    &__statistics {
      display: flex;
      gap: 23px;
      width: 100%;
      flex-direction: column;
      min-height: 500px;

      @media only screen and (min-width: 1240px) {
        flex-direction: row;
      }

      .statistics__right {
        display: flex;
        flex-direction: column;
        gap: 24px;
        flex: 1 0 auto;
        width: 100%;

        @media only screen and (min-width: 1240px) {
          max-width: 550px;
        }

        .statistic {
          &__row {
            display: flex;
            flex-wrap: wrap;
            gap: 24px;
            flex-grow: 2;

            &--small {
              flex-grow: 1;
            }
          }
        }
      }
    }
  }

  .loader-wrapper {
    min-height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
