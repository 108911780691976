.content-container {
  .user-settings {
    max-width: 700px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 0 auto;
    height: calc(100vh - 236px);

    .k-form-field {
      margin-top: 12px;
    }

    .k-input {
      padding: 12px 24px;
      margin-top: 12px;
      border-radius: 30px;
      font-size: 1rem;
      font-family: var(--font-family);
      box-shadow: unset;
    }

    .k-floating-label-container.k-empty > .k-label {
      top: 45px;
      left: 26px;
      font-size: 1rem;
    }

    .k-form-error {
      padding-left: 18px;
      padding-top: 10px;
    }

    .k-form-buttons {
      margin-top: 0;
    }

    .k-text-end {
      padding-top: 10px;
      padding-right: 18px;
    }

    .button-container {
      margin-left: auto;
      margin-top: 24px;

      &--center {
        margin-right: auto;
      }
    }

    p {
      margin-bottom: 32px;
    }

    button {
      width: fit-content;
      margin: 0 auto;
    }
  }

  .error {
    color: red;
  }
}
