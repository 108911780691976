.content-container {
  .error-handling {
    max-width: 700px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 0 auto;
    height: calc(100vh - 236px);

    h1 {
      text-align: center;
    }

    p {
      text-align: center;

      &.error {
        color: var(--light-gray-darker);
      }
    }

    .buttons {
      display: flex;
      justify-content: center;
      gap: 20px;
      margin-top: 10px;

      button {
        width: fit-content;
        margin: 0;
      }
    }
  }
}
