.drag-hint {
  position: absolute;
  top: 0;
  left: 50%;
  display: flex;
  flex-direction: column;
  pointer-events: none;
  gap: 8px;
  transform: translateX(-50%);

  &__orb {
    width: 45px;
    height: 45px;
    border: 1px solid var(--black-gray);
    background-color: var(--white);
    border-radius: 50%;
    pointer-events: none;
    margin: 0 auto;
  }

  &__content {
    text-align: center;
    font-size: 12px;
    color: var(--gray);
    display: flex;
    gap: 8px;
  }
}

.question-slider {
  display: flex;
  gap: 12px;
  margin-top: 80px;
  flex-direction: column;

  @media only screen and (min-width: 1024px) {
    flex-direction: row;
  }

  &__container {
    background-color: var(--gray-blue);
    border-radius: 12px;
    height: 20px;
    margin-top: 10px;

    @media only screen and (min-width: 1024px) {
      margin-top: 0;
    }
  }

  .k-draghandle {
    display: none;
  }

  &--has-value {
    .drag-hint {
      display: none;
    }

    .k-draghandle {
      display: block;
      background: var(--white) !important;
      border-color: var(--black-gray) !important;

      &::after {
        display: block;
        font-size: 20px;
        line-height: 45px;
        content: attr(aria-valuenow);
        color: var(--black-gray);
      }
    }

    .question-slider__container {
      background: linear-gradient(to right, var(--green), var(--gray-blue) 50%);
    }
  }

  &__label {
    max-width: 145px;
    width: 100%;
    display: none;
    align-items: center;
    font-size: 1rem;
    font-size: clamp(1rem, 0.975rem + 0.125vw, 1.125rem); // 16px to 18px

    @media only screen and (min-width: 1024px) {
      display: inline-flex;
    }
  }

  &--mobile {
    display: flex;
    justify-content: space-between;
    gap: 40px;
    width: 100%;
    margin-top: 50px;

    @media only screen and (min-width: 1024px) {
      display: none;
    }

    .label {
      text-align: left;

      &--right {
        text-align: right;
      }
    }
  }

  &__slider {
    flex: 2;
    position: relative;
    height: 46px;
    display: flex;
    margin: 0 10px;
    flex-direction: column;
    justify-content: space-around;

    .k-slider-horizontal {
      width: 100%;

      .k-slider-track-wrap {
        height: 20px;
      }

      .k-slider-selection,
      .k-slider-track {
        height: 20px;
        border-radius: 12px;
        top: 0;
        margin-top: 0;
        background-color: var(--gray-blue);
      }
    }

    .k-slider {
      .k-slider-selection {
        background-color: var(--green);
      }

      .k-slider-track-wrap {
        margin: 0 !important;

        .k-slider-items {
          position: relative;
          margin: 0 15px !important;
        }

        .k-tick {
          width: 2px;
          height: 2px;
          border-radius: 2px;
          top: 50%;
          transform: translateY(-1px) translateX(-50%);
          background-image: none;
          background-color: var(--white);
          display: block;
          min-height: unset;
        }
      }
    }

    .k-draghandle {
      background-color: var(--white);
      border: 1px solid var(--black-gray);
      width: 46px;
      height: 46px;

      &:active,
      &:hover {
        background-color: var(--white);
        border-color: var(--green);
      }
    }
  }
}
