.error-messages {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  font-weight: bold;
  text-align: center;
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10003;

  &__error {
    cursor: pointer;
    border: unset;
    background-color: var(--error);
    padding: 20px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    gap: 10px;

    span {
      display: flex;
      align-items: center;
    }

    span.error-message {
      font-size: 1rem;
      color: var(--white);
    }
  }
}
