.question-button {
  --text-color: var(--black-gray);
  --background-color: var(--light-gray-blue);

  font-family: var(--font-family);
  font-size: 1rem;
  font-size: clamp(1rem, 0.95rem + 0.25vw, 1.25rem);
  font-weight: bold;
  display: flex;
  align-items: center;
  padding: 19px 24px;
  cursor: pointer;
  border: 0;
  border-radius: 10px;
  box-shadow: none;
  color: var(--text-color);
  background-color: var(--background-color);
  transition: 0.3s ease-in-out;
  width: 100%;

  @media only screen and (min-width: 756px) {
    width: auto;
    justify-content: center;
  }

  &:hover,
  &--active {
    --background-color: var(--green);
    --text-color: var(--dark-green);
  }
}
