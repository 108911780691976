.modal {
  &__background {
    backdrop-filter: blur(5px);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 5;
  }

  &__content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(90% - 64px);
    max-width: 400px;
    max-height: 90%;
    background-color: var(--white);
    border-radius: 5px;
    z-index: 10;
    padding: 32px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

    &--wide {
      max-width: 800px;
    }

    h2,
    p {
      margin-top: 0;
    }

    .modal__buttons {
      display: flex;
      justify-content: center;
      gap: 20px;
      margin-top: 32px;

      button {
        width: fit-content;
        margin: 0;

        &--confirm {
          background-color: #e84555;
          color: var(--white);
        }
      }
    }

    .modal__copy {
      display: flex;
      gap: 20px;

      &__value {
        flex: 1;
        flex-wrap: wrap;
        word-break: break-all;
        font-size: 0.8rem;
        text-align: left;
      }

      &__button {
        background-color: var(--black-gray);
        color: var(--white);
        width: 50px;
        border-radius: 40px;
        height: 50px;
        cursor: pointer;
        border: none;
        transition: 0.3s ease-in-out;

        &:hover {
          color: var(--black-gray);
          background-color: var(--light-gray-blue);
        }
      }
    }
  }
}
