.domain-info {
  display: flex;
  flex-direction: column;
  gap: 16px;
  text-align: left;
  width: 90%;
  margin: 0 auto;

  @media only screen and (min-width: 768px) {
    width: calc((100% - 143px) / 2);
    margin: 0;
  }

  @media only screen and (min-width: 1024px) {
    width: calc((100% - 192px) / 3);
  }

  &--tno {
    background-color: var(--light-gray-blue);
    padding: 24px;
    border-radius: 25px;

    @media only screen and (min-width: 1024px) {
      width: calc((100% - 192px) / 3);
    }
  }

  &__title {
    font-family: var(--font-family);
    color: var(--black-gray);
    font-size: 20px;
    line-height: 1.2;
    letter-spacing: -0.01em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 20px;

    &--scores {
      min-width: 40px;
    }

    &--score {
      font-family: var(--font-family-bold);
      font-size: 14px;
      color: var(--dark-blue);
    }

    &--compare-score {
      font-family: var(--font-family-bold);
      font-size: 14px;
      color: var(--green);
    }
  }

  &__separator {
    opacity: 0.2;
    border-bottom: 1px solid #30333a;
  }

  &__description {
    font-size: 14px;
    margin-bottom: 0;
    line-height: 1.5;
    min-height: 70px;
  }

  &__statistics {
    display: none;
    flex-direction: column;
    gap: 8px;

    &--show {
      display: flex;
    }
  }
}

.domain-stat__score {
  display: flex;
  flex-direction: column;

  &-bar {
    --bar-height: 15px;

    transition: width 0.2s ease;
    background-color: var(--dark-blue);
    height: var(--bar-height);
    position: relative;

    &--comparison {
      background-color: var(--green);
    }

    &::after {
      position: absolute;
      right: -15px;
      line-height: var(--bar-height);
      font-size: 11px;
      content: attr(data-score);
    }
  }
}

.domain-stat {
  display: flex;
  justify-content: flex-start;
  gap: 12px;

  .domain-stat__name {
    flex: 1;
    min-width: 40%;
    max-width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    font-size: 0.625rem;
    font-size: clamp(0.625rem, 0.575rem + 0.25vw, 0.875rem);
    overflow-wrap: break-word;
  }

  .domain-stat__score {
    flex: 2;

    &--hide-value {
      ::after {
        display: none;
      }
    }
  }
}
