.modal {
  &__background {
    backdrop-filter: blur(5px);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 5;
  }

  &__content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(90% - 64px);
    max-width: 400px;
    max-height: 90%;
    background-color: var(--white);
    border-radius: 5px;
    z-index: 10;
    padding: 32px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

    h2,
    p {
      margin-top: 0;
    }

    button {
      width: fit-content;
      margin: 0;

      &.button--text {
        &:hover {
          background: unset;
        }
      }
    }
  }

  &__button-container {
    display: flex;
    gap: 16px;
    justify-content: center;
  }
}
