@font-face {
  font-family: Aventa;
  src: url('../public/fonts/Aventa Regular.ttf') format('truetype');
}

@font-face {
  font-family: Aventa-SemiBold;
  src: url('../public/fonts/Aventa Semi Bold.ttf') format('truetype');
}

@font-face {
  font-family: Aventa-Medium;
  src: url('../public/fonts/Aventa Medium.ttf') format('truetype');
}

@font-face {
  font-family: Aventa-Bold;
  src: url('../public/fonts/Aventa Bold.ttf') format('truetype');
}

:root {
  // Color variables
  --white: #ffffff;
  --light-gray: #d6d6d8;
  --light-gray-darker: #d8d8d8;
  --light-gray-blue: #f0f2f7;
  --gray-blue: #e8ebf2;
  --gray: #5b5f69;
  --black-gray: #30333a;
  --dark-blue: #194867;
  --blue: #4f8fda;
  --light-blue: #4f90da42;
  --pink: #dba4c1;
  --dark-pink: #472637;
  --dark-green: #20540d;
  --green: #bceaaa;
  --orange: #f38e6d;
  --error: #ff5866;
  --disabled-button-color: rgba(91, 95, 105, 0.6);
  --disabled-button-background-color: rgba(48, 51, 58, 0.1);
  --button-active-color: var(--white);

  // Breakpoints
  --breakpoint-mobile: 768px;

  // Fonts
  --font-family: aventa-medium, roboto, sans-serif;
  --font-family-semibold: aventa-semibold, roboto, sans-serif;
  --font-family-bold: aventa-bold, roboto, sans-serif;

  font-size: 16px;
  color: var(--black-gray);
}

body {
  margin: 0;
  padding: 0 !important;
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .content-container,
  .navigation {
    width: 90%;
    max-width: 1280px;
    margin: 0 auto;

    @media only screen and (min-width: var(--breakpoint-desktop)) {
      width: 100%;
    }
  }
}

h1 {
  font-size: 2rem;
  font-size: clamp(2rem, 1.4rem + 3vmin, 3rem);
  margin: 0;
}

h3 {
  font-size: 1.25rem;
  font-size: clamp(1.25rem, 1.2rem + 0.25vw, 1.5rem);
  margin: 0;
  font-family: var(--font-family-bold);
}
