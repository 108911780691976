.list-grid-navigation {
  width: 100%;
  text-align: right;
  padding-top: 24px;
  padding-bottom: 32px;

  a {
    color: var(--black);
    text-decoration: none;
    font-size: 1rem;
    font-weight: 600;

    &.upload-button {
      display: block;
      margin-top: 10px;
      width: fit-content;
      margin-left: auto;
      font-size: 14px;
    }
  }
}

.event-log {
  list-style: none;
  padding: 0;
  margin-bottom: 16px;
  max-height: 260px;
  overflow-y: auto;

  li {
    padding: 10px 5px;

    &:nth-child(even) {
      background: var(--light-gray-blue);
    }

    .item {
      text-align: left;
      display: flex;
      justify-content: space-between;

      .email {
        text-align: left;
        width: 60%;
        white-space: pre-wrap;
      }

      .reason {
        text-align: left;
        width: 35%;
      }
    }

    b {
      padding-bottom: 10px;
      display: block;
    }
  }
}

.copy-event-log {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-bottom: 30px;

  button {
    background: transparent;
    border: none;
    color: var(--black);
    font-family: var(--font-family);
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
  }
}
