.period-filter {
  display: flex;
  border: 1px solid var(--light-gray-darker);
  border-radius: 4px;
  width: 100%;

  & > div {
    width: 100%;
  }

  @media only screen and (min-width: 1024px) {
    margin-left: 12px;
    width: 250px;
  }

  .react-select {
    &__indicator {
      color: var(--black);
      padding: 0;

      &-separator {
        display: none !important;
      }
    }

    &__control {
      padding: 13px 12px 11px;
      box-shadow: none;
      cursor: pointer;
      border: 0;
      border-bottom: 1px solid var(--light-gray-darker);
      border-radius: 0;

      &:focus,
      &:hover {
        border: 0;
        border-bottom: 1px solid var(--light-gray-darker);
      }

      @media only screen and (min-width: 1024px) {
        border: 0;
        padding: 13px 12px 11px;
        width: 100%;

        &:focus,
        &:hover {
          border: 0;
        }
      }
    }

    &__multi-value {
      background: var(--gray-blue) !important; // React Select overrides
      color: var(--black-gray) !important; // React Select overrides
      border-radius: 24px !important; // React Select overrides
      padding: 4px 8px !important; // React Select overrides

      &__remove {
        color: var(--black-gray) !important; // React Select overrides;
        cursor: pointer;
        border-radius: 50% !important; // React Select overrides;s

        &:hover {
          background: var(--gray-blue) !important; // React Select overrides;
          color: var(--black-gray) !important; // React Select overrides;
        }
      }
    }
  }
}
