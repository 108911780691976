.data-grid-selector {
  &__content {
    margin-left: auto;
    width: fit-content;
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid var(--gray-blue);
    border-left: 1px solid var(--gray-blue);
    border-radius: 20px 20px 0 0;
    overflow: hidden;
  }

  &__item {
    background: unset;
    border: unset;
    border-right: 1px solid var(--gray-blue);
    padding: 12px 24px;
    cursor: pointer;
    transition: 0.1s ease-in-out;

    &:hover,
    &--selected {
      background: var(--black-gray);
      color: var(--white);

      &:hover {
        &:disabled {
          background: var(--light-gray);
        }
      }
    }
  }
}
