.full-height {
  height: 100vh;
  display: flex;
  flex-direction: column;

  .user-settings {
    text-align: center;
    flex: 2;
  }
}
