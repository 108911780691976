.button {
  --text-color: var(--white);
  --background-color: var(--black-gray);

  font-family: var(--font-family);
  font-size: 1rem;
  font-size: clamp(1rem, 0.975rem + 0.125vw, 1.125rem);
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 54px;
  padding: 24px;
  cursor: pointer;
  border: 0;
  border-radius: 50px;
  box-shadow: none;
  gap: 24px;
  white-space: nowrap;
  color: var(--text-color);
  background-color: var(--background-color);
  transition: 0.3s ease-in-out;

  @media (hover: hover) {
    &:hover:not(.button--disabled) {
      --text-color: var(--black-gray);
      --background-color: var(--light-gray-blue);
    }
  }

  @media only screen and (min-width: 375px) {
    padding: 24px 32px;
  }

  &__label {
    display: inline-flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    pointer-events: none;

    &::after {
      font-weight: bold;
      visibility: hidden;
      overflow: hidden;
      height: 0;
      content: attr(data-label);
      user-select: none;
      pointer-events: none;

      @media speech {
        display: none;
      }
    }
  }

  &__icon {
    pointer-events: none;
  }

  &--has-icon {
    justify-content: space-between;
  }

  &--icon-left {
    flex-direction: row-reverse;

    .button__icon {
      margin-right: 8px;
    }
  }

  &--icon-right {
    .button__icon {
      margin-left: 8px;
    }
  }

  &--inverted {
    --text-color: var(--black-gray);
    --background-color: var(--white);

    border: 1px solid var(--black-gray);
  }

  path {
    fill: var(--text-color);
  }

  &--danger {
    --background-color: #ec2d4d;
    --text-color: var(--white);

    &:hover {
      --background-color: #be1330;
      --text-color: var(--white);
    }
  }

  &--disabled {
    --background-color: var(--disabled-button-background-color);
    --text-color: var(--disabled-button-color);

    border-color: var(--text-color);

    &.button--inverted {
      --background-color: var(--white);
    }
  }

  &--text {
    --text-color: var(--black-gray);
    --background-color: transparent;

    padding-left: 0;
    padding-right: 0;
  }

  &--stripped {
    border: none;
    display: block;
    color: var(--black-gray);
    background: unset;
    font-family: var(--font-family);
    font-weight: bold;
    font-size: 1rem;
    font-size: clamp(1rem, 0.975rem + 0.125vmin, 1.125rem); // 16 to 18px
    cursor: pointer;
  }
}
