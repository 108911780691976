nav {
  .navigation {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;

    @media only screen and (min-width: 869px) {
      height: 118px;
      align-items: center;
    }

    &--has-border {
      border-bottom: 1px solid var(--light-gray);
    }

    &__content {
      display: flex;
      gap: 12px;
      margin-top: 0;

      @media only screen and (max-width: 869px) {
        flex-direction: column;
        margin-top: 16px;
      }

      .button {
        padding: 12px 24px;
        font-size: clamp(1rem, 0.975rem + 0.125vw, 1.125rem);
        font-weight: normal;
        min-height: auto;

        @media only screen and (max-width: 869px) {
          background-color: var(--white);
          color: var(--blue);
          font-weight: bold;
          justify-content: left;
          padding: 5px 0;
        }
      }
    }

    &__title {
      text-align: left;
    }

    &__left {
      display: none;
      padding-right: 16px;
      text-align: left;

      &--has-title {
        display: block;
      }

      p {
        font-size: 1.25rem;
        font-size: clamp(1.25rem, 1.2rem + 0.25vw, 1.5rem);
        font-family: var(--font-family);
      }
    }

    &__pagination {
      display: none;

      @media only screen and (min-width: 1024px) {
        position: absolute;
        transform: translateX(-50%);
        left: 50%;
      }

      &--has-pagination {
        display: block;
      }

      p {
        font-size: 1rem;
        font-size: clamp(1rem, 0.975rem + 0.125vw, 1.125rem); // 16px to 18px
        font-family: var(--font-family-semibold);
        text-transform: uppercase;
      }
    }

    &__right {
      display: flex;
      flex-direction: column-reverse;
      align-items: flex-end;
      margin-top: 20px;
      margin-left: auto;
      gap: 32px;
      height: 100%;

      @media only screen and (min-width: 869px) {
        flex-direction: row;
        align-items: center;
        margin-top: 0;
      }
    }
  }

  .react-select,
  .react-select__transparent {
    &__control {
      background: var(--light-gray-blue);
      border: unset;
      padding: 12px;
      border-radius: 22px;
      box-shadow: none;
      cursor: pointer;
      min-width: 80px;

      @media only screen and (min-width: 1024px) {
        padding: 12px 24px;
      }
    }

    &__indicator {
      color: var(--black);
      padding: 0;

      &-separator {
        display: none;
      }
    }

    &__value-container {
      font-size: 1rem;
      font-size: clamp(1rem, 0.975rem + 0.125vw, 1.125rem); // 16px to 18px
      padding: 0 0 0 8px;

      @media only screen and (min-width: 1024px) {
        padding: 0 4px 0 0;
      }
    }

    &__transparent {
      &__control {
        background: unset;
        padding: 12px 0 12px 24px;
      }
    }
  }

  .profile {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    height: 100%;

    &__picture {
      background-image: url('../../../../public/images/general/profile.png');
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      width: 43px;
      height: 43px;
      border-radius: 50%;
      margin-right: 0;
      display: block;
      border: 0;

      @media only screen and (min-width: 1024px) {
        display: none;
        margin-right: 32px;
      }
    }

    &__name {
      font-size: clamp(1rem, 0.975rem + 0.125vw, 1.125rem); // 16 to 18px
      align-items: center;
      display: none;
      background-color: unset;
      border: 0;
      font-family: var(--font-family);
      cursor: pointer;
      padding: 0;
      max-width: 200px;
      overflow-wrap: anywhere;
      text-align: left;

      @media only screen and (min-width: 1024px) {
        display: flex;
      }

      img {
        width: 20px;
        margin-left: 12px;
        transition: 0.3s ease-in-out;
        transform: rotate(0deg);

        &.active {
          transform: rotate(180deg);
        }
      }
    }

    &__icon {
      margin-left: 12px;

      svg {
        margin-top: 7px;
        width: 30px;
      }

      #top-line,
      #bottom-line,
      #middle-line {
        transform-box: fill-box;
        transform-origin: center;
      }

      .active {
        #top-line {
          animation: down-rotate 0.6s ease-out both;
        }

        #bottom-line {
          animation: up-rotate 0.6s ease-out both;
        }

        #middle-line {
          animation: hide 0.6s ease-out forwards;
        }
      }

      @keyframes up-rotate {
        0% {
          animation-timing-function: cubic-bezier(0.16, -0.88, 0.97, 0.53);
          transform: translateY(0);
        }

        30% {
          transform-origin: center;
          animation-timing-function: cubic-bezier(0.34, 1.56, 0.64, 1);
          transform: translateY(-10px);
        }

        100% {
          transform-origin: center;
          transform: translateY(-10px) rotate(45deg) scale(0.9);
        }
      }

      @keyframes down-rotate {
        0% {
          animation-timing-function: cubic-bezier(0.16, -0.88, 0.97, 0.53);
          transform: translateY(0);
        }

        30% {
          transform-origin: center;
          animation-timing-function: cubic-bezier(0.34, 1.56, 0.64, 1);
          transform: translateY(10px);
        }

        100% {
          transform-origin: center;
          transform: translateY(10px) rotate(-45deg) scale(0.9);
        }
      }

      @keyframes hide {
        29% {
          opacity: 1;
        }

        30% {
          opacity: 0;
        }

        100% {
          opacity: 0;
        }
      }
    }

    &__menu {
      position: absolute;
      top: 100%;
      right: 0;
      display: none;
      background-color: var(--white);
      box-shadow: 0 6px 15px -3px rgba(0, 0, 0, 0.1);

      &--active {
        display: block;
        z-index: 101;
      }

      ul {
        padding: 20px;
        list-style: none;
        cursor: default;

        li {
          list-style: none;
          padding: 0;
          padding-bottom: 20px;
          text-align: left;

          a {
            text-decoration: none;
            color: var(--black);
            white-space: nowrap;
            display: inline-block;
            width: 100%;

            &:hover {
              font-weight: 600;
            }
          }

          button {
            border: none;
            cursor: pointer;
            background-color: unset;
            padding: 0;
            font-size: 1rem;
            font-family: var(--font-family);
            color: var(--black-gray);
            width: 100%;
            text-align: left;

            &:hover {
              font-weight: 600;
            }
          }

          &:last-child {
            padding-bottom: 0;
          }
        }
      }
    }
  }
}

.mobile-buttons {
  text-align: left;
  position: relative;
  top: -43px;

  @media only screen and (min-width: 650px) {
    display: none;
  }
}

.take-survey-button,
.take-survey-button__mobile {
  background: var(--black-gray);
  border-radius: 22px;
  padding: 12px 24px;
  text-decoration: none;
  color: var(--white);
  font-size: 1rem;
  font-size: clamp(1rem, 0.975rem + 0.125vw, 1.125rem);
  transition: 0.3s ease-in-out;
  min-width: fit-content;
  display: none;

  &:hover {
    background: var(--light-gray-blue);
    color: var(--black-gray);
  }

  @media only screen and (min-width: 650px) {
    display: initial;
  }
}

.take-survey-button__mobile {
  display: inline-block;
}
