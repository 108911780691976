.chart-explanation {
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-direction: column;

  @media only screen and (min-width: 860px) {
    flex-direction: row;
    margin-bottom: 54px;
  }

  @media only screen and (min-width: 1024px) {
    margin-top: 76px;
  }

  .content-left {
    width: 100%;
    max-width: 550px;
    text-align: left;
    margin-right: auto;
  }

  .content-right {
    width: 100%;
    margin-top: 40px;

    @media only screen and (min-width: 756px) {
      width: auto;
      justify-content: center;
    }

    .toggle-button {
      .filter-button {
        border: 1px solid var(--light-gray-darker);
        border-radius: 4px;
        display: flex;
        justify-content: center;
        width: 100%;
      }
    }
  }

  h3 {
    font-size: 1.125rem;
    font-size: clamp(1.125rem, 1.05rem + 0.375vw, 1.5rem);
  }

  &__legend {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;

    @media only screen and (min-width: 360px) {
      gap: 20px;
    }

    .legend__info-row {
      p {
        font-size: 1rem;
        line-height: 1.5;
        position: relative;
        margin: 0;
        margin-left: 20px;

        &::before {
          content: '';
          position: absolute;
          display: inline-block;
          width: 13px;
          height: 13px;
          border-radius: 50%;
          left: -20px;
          background: var(--dark-blue);
          top: 50%;
          transform: translateY(-50%);
        }

        &.green {
          &::before {
            background: rgba(188, 234, 170, 1);
          }
        }
      }
    }
  }
}
