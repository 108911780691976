.stat-block {
  width: 100%;
  height: calc(100% - 48px);
  padding: 24px;
  background: var(--background-color);
  border-radius: 25px;
  color: var(--text-color);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 15px;
  text-decoration: none;
  position: relative;

  @media only screen and (min-width: 740px) {
    width: calc(50% - 60px);
  }

  @media only screen and (min-width: 1024px) {
    flex-direction: column;
  }

  &--no-score {
    justify-content: space-around;
  }

  &__score {
    display: flex;
    font-family: aventa-bold, sans-serif;

    &--large {
      font-size: 3.75rem;
      font-size: clamp(3.75rem, 3.25rem + 2.5vw, 6.25rem);
      line-height: 1;
    }

    &--small {
      font-size: 2rem;
      font-size: clamp(2rem, 1.825rem + 0.875vw, 2.875rem);
      line-height: 1.2;
      font-family: var(--font-family);
    }
  }

  &__content {
    text-align: left;

    span {
      display: block;
      font-size: 1rem;
      text-transform: uppercase;
      margin-top: 10px;
      margin-bottom: 10px;
      font-family: var(--font-family-semibold);
    }

    h3 {
      font-size: 1.5rem;
      font-size: clamp(1.5rem, 1.4rem + 0.5vw, 2rem);
      font-family: var(--font-family-bold);
    }

    p {
      line-height: 1.5;
    }
  }

  &__description {
    font-size: 1rem;
    font-size: clamp(1rem, 0.975rem + 0.125vw, 1.125rem); // 16px to 18px
    line-height: 1.2;
    max-width: 100%;
    display: inline-block;
    display: flex;
    align-items: center;
    text-align: left;

    span {
      overflow-wrap: break-word;
      width: 100%;
    }
  }

  &--blue {
    --text-color: var(--white);
    --background-color: var(--dark-blue);
  }

  &--orange {
    --text-color: var(--white);
    --background-color: var(--orange);
  }

  &--green,
  &--yellow {
    --text-color: var(--dark-green);
    --background-color: var(--green);

    justify-content: center;
    flex-direction: column;

    .stat-block__score {
      justify-content: center;

      &--large {
        font-size: 3.75rem;
        font-size: clamp(3.75rem, 2.8125rem + 4.6875vw, 8.4375rem);
        color: var(--white);
      }
    }

    .stat-block__description {
      text-align: center;
      margin: 0 auto;
      display: inline-block;
      max-width: 265px;
    }

    width: 100%;
  }

  &--yellow {
    --text-color: var(--white);
    --background-color: var(--orange);
  }
}
