.survey-navigation {
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    margin-bottom: 60px;
    justify-content: center;

    @media only screen and (min-width: 1024px) {
      justify-content: flex-end;
    }

    @media only screen and (min-width: 430px) {
      flex-direction: row;
    }

    .buttons {
      display: flex;
      align-items: center;
      gap: 24px;

      .submit-button {
        display: grid;

        > * {
          grid-column: 1;
          grid-row: 1;
        }

        .loader {
          height: 100%;
          width: 100%;
          margin: 0 auto;
          display: flex;
          justify-content: center;
          flex-direction: column;
          background-color: var(--black-gray);
          z-index: 1;
          border-radius: 50px;

          .lds-ellipsis {
            margin: 0 auto;
          }
        }
      }
    }

    .button {
      &--stripped {
        &.hide {
          display: none;
        }
      }
    }
  }

  .privacy-policy {
    display: flex;
    gap: 10px;
    margin-right: auto;

    &--tno {
      align-items: center;

      .checkbox-wrapper {
        display: flex;
        align-content: center;
      }
    }

    .checkbox-wrapper {
      display: unset;
      align-content: unset;
    }

    &__statements {
      font-size: 1rem;
      color: var(--c-black-darkgrey);
      max-width: 780px;

      p {
        text-align: left;
        margin: 0;
        margin-top: 2px !important;

        button {
          font-size: 1rem;
          text-align: left;
        }
      }

      p + p {
        margin-top: 16px !important;
      }
    }
  }
}
