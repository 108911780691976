.survey {
  min-height: calc(100vh - 328px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 60px;
  padding: 40px 0;

  &--start {
    min-height: calc(100vh - 318px);

    .paragraph {
      p {
        font-size: 1.125rem !important;
        font-size: clamp(1.125rem, 1.1rem + 0.125vw, 1.25rem) !important;
      }

      @media only screen and (min-width: 1305px) {
        p {
          text-align: center;
        }
      }
    }
  }

  .survey-question {
    &__title {
      text-align: center;
      max-width: 1024px;
      margin: 0 auto;
      word-break: break-word;
      white-space: pre-line;

      @media only screen and (max-width: 1024px) {
        h1.question-title {
          font-size: clamp(1.5rem, 1.4rem + 3vmin, 1.5rem);
        }
      }
    }

    &__text {
      max-width: 677px;
      width: 100%;
      margin: 46px auto 0;
      font-size: 1.125rem;
      font-size: clamp(1.125rem, 1.1rem + 0.125vw, 1.25rem);
      line-height: 1.5;
      text-align: center;
    }

    &__answers {
      display: flex;
      justify-content: space-around;
    }

    &__multiple-choice {
      display: flex;
      gap: 16px;
      margin-top: 60px;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;
    }

    &__slider {
      flex-grow: 1;
    }

    &__input {
      display: flex;
      width: 80%;
      margin-top: 20px;

      textarea,
      input {
        --text-color: var(--black-gray);
        --background-color: var(--light-gray-blue);

        font-family: var(--font-family);
        font-size: 1rem;
        font-size: clamp(1rem, 0.95rem + 0.25vw, 1.25rem);
        font-weight: bold;
        display: flex;
        align-items: center;
        padding: 19px 24px;
        height: 120px;
        border: 0;
        border-radius: 10px;
        box-shadow: none;
        color: var(--text-color);
        background-color: var(--background-color);
        transition: 0.3s ease-in-out;
      }
    }
  }
}

.privacy-policy {
  p {
    margin-top: 0 !important;

    button {
      font-weight: 600;
      color: var(--blue);
      text-decoration: none;
      font-size: 1.125rem;
      font-size: clamp(1.125rem, 1.1rem + 0.125vw, 1.25rem);
      line-height: 1.5;
      letter-spacing: 0;
      border: unset;
      background-color: unset;
      cursor: pointer;
      padding: 0;
    }
  }
}

.survey-section {
  .content-container.tno {
    .overview__intro {
      .paragraph p {
        font-size: 1.4rem !important;
      }
    }
  }
}
