.survey-completed-page {
  .content-container {
    &__content {
      max-width: 700px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      margin: 0 auto;
      height: calc(100vh - 236px);

      h1 {
        b {
          color: var(--blue);
        }
      }
    }
  }
}
