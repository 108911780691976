.checkbox-wrapper {
  display: flex;
  align-items: center;
  gap: 5px;

  &__checkbox {
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 4px;
    background-color: var(--white);
    border: 1px solid var(--black-gray);
    outline: none;
    cursor: pointer;
    transition: 0.3s ease-in-out;

    &:checked {
      background-color: var(--blue);
      border: 1px solid var(--blue);
      position: relative;
      transition: 0.3s ease-in-out;

      &::before {
        content: url('../../../../public/images/icons/checkboxCheckmark.svg');
        width: 10px;
        height: 10px;
        font-size: 1.5em;
        color: var(--c-white);
        position: absolute;
        left: 50%;
        top: 0;
        transform: translate(-50%, -50%);
      }
    }

    &:disabled {
      background-color: var(--gray-blue);
      border-color: var(--gray-blue);
      cursor: not-allowed;
    }

    &:disabled + span {
      color: var(--c-blackgrey);
    }

    &--blue {
      &:checked {
        background-color: var(--c-darkblue);
        border: 1px solid var(--c-darkblue);
      }
    }
  }

  &__label {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;

    img {
      max-width: 25px;
    }
  }
}
