.feedback-button {
  position: fixed;
  padding: 12px 24px;
  top: 50%;
  right: -45px;
  z-index: 1000;
  border-radius: 10px 10px 0 0;
  cursor: pointer;
  background-color: var(--gray-blue);
  transition: all 0.3s ease-in-out;
  transform: rotate(-90deg) translate(0, 0);
  text-decoration: none;
  color: var(--black-gray);
  display: inline-block;

  &:hover {
    background: var(--black-gray);
    color: var(--white);
  }
}
