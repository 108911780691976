.stat-block-employer {
  width: calc(100% - 48px);
  padding: 24px;
  background: var(--background-color);
  border-radius: 25px;
  color: var(--text-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  position: relative;

  @media only screen and (min-width: 530px) {
    width: calc(50% - 60px);
    flex-direction: row;
    gap: 30px;
  }

  .stat-block-employer__row {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;

    @media only screen and (min-width: 530px) {
      flex-direction: column;
      width: 159px;
    }

    .score {
      font-family: aventa-bold, sans-serif;
      font-family: var(--font-family-bold);
      display: flex;
      align-items: flex-start;
      justify-content: center;
      min-width: 60px;

      @media only screen and (min-width: 400px) {
        min-width: 80px;
      }

      @media only screen and (min-width: 1024px) {
        min-width: 100px;
      }

      &--large {
        font-size: 1.8rem;
        line-height: 1;
        color: var(--white);

        @media only screen and (min-width: 400px) {
          font-size: 2.5rem;
        }

        @media only screen and (min-width: 1024px) {
          font-size: 3rem;
        }
      }

      &--small {
        font-size: 1rem;
        font-size: 18px;
        line-height: 1;
        margin-top: 0;

        @media only screen and (min-width: 400px) {
          font-size: 1.5rem;
          margin-top: 5px;
        }

        @media only screen and (min-width: 1024px) {
          font-size: 2rem;
        }
      }
    }

    p {
      margin: 0;
      font-size: 1rem;
      line-height: 1.5;
      max-width: 100%;
      width: calc(100% - 80px);
      display: inline-block;
      text-align: left;
      font-family: var(--font-family);
      overflow-wrap: break-word;

      @media only screen and (min-width: 530px) {
        text-align: center;
        width: 100%;
      }

      b {
        font-family: var(--font-family-bold);
      }
    }
  }

  &--blue {
    --text-color: var(--white);
    --background-color: var(--dark-blue);
  }

  &--green {
    --text-color: var(--dark-green);
    --background-color: var(--green);

    .stat-block-employer__row {
      .score {
        &--large {
          color: var(--dark-green);
        }
      }
    }
  }
}
