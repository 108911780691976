.k-grid {
  position: relative;
  border-radius: 0 0 20px 20px;
  margin-bottom: 80px;

  .k-grid-button {
    background-color: var(--black-gray);
    border: initial;
    border-radius: 40px;
    color: var(--white);
    cursor: pointer;
    font-family: var(--font-family-bold);
    padding: 12px 24px;
    transition: all 0.3s ease-in-out;

    &:hover {
      color: var(--black-gray);
      background-color: var(--gray-blue);
    }
  }

  .k-grid-header {
    padding: 0;

    th {
      padding: 12px 10px;
    }
  }

  .k-grid-toolbar {
    background: unset;
    border: unset;
  }

  .k-grid-content {
    overflow-y: auto;

    .k-grid-table {
      thead,
      tbody {
        tr {
          td {
            padding: 12px 10px;
            text-overflow: ellipsis;
            overflow: hidden;
            height: 1.2em;
            white-space: nowrap;
          }
        }
      }
    }
  }

  .k-master-row {
    position: relative;
  }

  tbody > tr:not(.k-detail-row):hover {
    background-color: var(--light-gray-blue);
  }

  .k-button-solid-primary {
    border-radius: 40px;
    background: unset;
    color: var(--black-gray);
    padding: 8px 12px;
    border: unset;
    font-family: var(--font-family-bold);

    &:focus {
      box-shadow: unset;
    }

    &.add-new {
      margin-bottom: 20px;
    }
  }

  .k-button-solid-base {
    border-radius: 40px;
    border: unset;
    transition: all 0.3s ease-in-out;
    font-family: var(--font-family-bold);

    &:focus {
      box-shadow: unset;
    }
  }

  .react-select__team {
    position: absolute;
    z-index: 10;
  }
}

.no-teams {
  margin: 60px 0;
}

.backoffice {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 32px;

  .backoffice-title {
    h2 {
      text-align: left;
      margin: 0;
    }

    p {
      text-align: left;
      margin-top: 10px;
      margin-bottom: 0;
      max-width: 800px;
      line-height: 1.5;
    }
  }

  .user-management {
    margin-right: 16px;
    text-decoration: none;
    color: var(--black-gray);
    font-weight: 600;
  }

  .upload-button {
    border-radius: 40px;
    color: var(--white);
    background-color: var(--black-gray);
    padding: 12px 24px;
    border: unset;
    transition: all 0.3s ease-in-out;
    font-family: var(--font-family-bold);
    cursor: pointer;

    &:hover {
      color: var(--black-gray);
      background-color: var(--gray-blue);
    }
  }
}

.k-grid-toolbar {
  position: absolute !important;
  left: 0;
  bottom: 100%;

  .k-button.add-new {
    margin-bottom: 0;
  }

  .k-button {
    padding: 8px 0;
  }
}

.k-pager,
.k-table-thead {
  background: unset !important;
}

.k-table-thead {
  border-radius: 20px 0 0;
  overflow: hidden;
}

.k-form-fieldset {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.k-form {
  margin-top: 20px;
}

.k-window {
  border-radius: 20px;
  overflow: hidden;
}

.k-dialog-titlebar {
  background: var(--black-gray);
}

.k-form-buttons {
  display: flex;
  justify-content: flex-end;

  .k-button {
    border-radius: 40px;
    background: unset;
    color: var(--black-gray);
    padding: 12px 24px;
    border: unset;
    font-family: var(--font-family-bold);
    transition: 0.3s ease-in-out;
  }

  .k-button-solid-primary {
    background: var(--black-gray);
    color: var(--white);

    &:disabled {
      background: var(--light-gray);
    }
  }
}

.actions {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  height: unset !important;
}

.k-label {
  font-weight: bold;
}
