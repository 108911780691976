.content-container {
  .dashboard-header {
    width: 100%;
    max-height: 660px;
    align-items: center;
    padding: 50px 0;

    &__back {
      width: 100%;
      text-align: left;
      margin-bottom: 10px;

      button {
        background: unset;
        border: unset;
        cursor: pointer;
        padding: 0;
        font-size: 1rem;

        svg {
          margin-right: 5px;
          transition: 0.3s ease-in-out;
        }

        &:hover {
          svg {
            transform: translateX(-4px);
          }
        }
      }
    }

    &__title {
      width: 100%;
      max-width: 640px;
      text-align: left;
      overflow-wrap: break-word;

      b,
      strong {
        color: var(--blue);
      }

      &--medium {
        max-width: 780px;

        @media only screen and (min-width: 1305px) {
          text-align: center;
        }
      }

      &--wide {
        max-width: 950px;
      }

      h1 {
        width: 100%;
        font-size: 2.5rem;
        font-size: clamp(2.5rem, 2.35rem + 0.75vw, 3rem);
        text-align: left;
        margin: 0;

        b,
        strong {
          color: var(--blue);
        }
      }
    }
  }
}
