footer {
  background-color: var(--light-gray-blue);
  padding: 22px 0;

  .content-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 16px;

    @media only screen and (min-width: 1024px) {
      flex-direction: row;
    }
  }

  .footer {
    &__text {
      p {
        margin: 0;
        color: var(--gray);
      }
    }

    &__logos {
      padding-left: 16px;
      display: flex;
      flex-wrap: wrap;
      gap: 16px;

      @media only screen and (min-width: 1024px) {
        padding-left: 16px;
      }

      .logo {
        padding-right: 18px;
        display: flex;
        align-items: center;

        &:last-child {
          padding-right: 0;
        }

        img.deloitte {
          height: 12px;
        }

        img.zilveren-kruis {
          height: 22px;
        }

        img.tno {
          height: 30px;
        }

        img.dsm {
          height: 16px;
        }

        img.trimbos {
          height: 36px;
        }
      }
    }
  }
}
