.spiderweb {
  background: var(--light-gray-blue);
  border-radius: 25px;
  width: 100%;
  height: 100%;
  min-height: 340px;
  padding: 130px 0;
  position: relative;
  overflow: hidden;
  transition: 0.3s ease-in-out;

  @media only screen and (min-width: 425px) {
    padding: 110px 0 150px;
  }

  @media only screen and (min-width: 1240px) {
    max-width: 740px;
    width: 100%;

    &--wide {
      max-width: 100%;
    }
  }

  canvas {
    height: auto;
    max-width: 500px;
    width: 100%;
    margin: 0 auto;
    z-index: 2;
    position: relative;

    @media only screen and (min-width: 1240px) {
      max-width: 600px;
      width: 100%;
    }
  }

  &__content {
    position: absolute;
    top: 30px;
    width: calc(100% - 40px);
    height: calc(100% - 30px);
    padding: 0 24px;
    justify-content: space-between;
    flex-direction: column;
    left: 0;
    z-index: 1;

    @media only screen and (min-width: 600px) {
      flex-direction: row;
      padding: 0 40px;
      width: calc(100% - 80px);
    }

    .content-bottom-left {
      position: absolute;
      bottom: 25px;
      right: 50%;
      transform: translateX(50%);
      max-width: 300px;
      width: 80%;

      @media only screen and (min-width: 600px) {
        left: 40px;
        transform: translateX(0%);
      }

      .content-legend {
        width: 100%;
        text-align: left;

        p {
          margin: 0;

          span {
            font-size: 0.75rem;
            line-height: 1.7;
            display: inline-block;
            width: calc(100% - 41px);

            &.score {
              width: 41px;
              margin-bottom: auto;
            }
          }
        }
      }
    }

    .content-top-left {
      left: 0;

      .title {
        margin-bottom: 8px;

        h3 {
          font-size: 1.25rem;
          font-size: clamp(1.25rem, 1.225rem + 0.125vw, 1.375rem);
          text-align: left;
        }
      }

      .legend {
        &__info-row {
          width: fit-content;
          text-align: left;
          margin-left: 0;
          margin-right: auto;
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          @media only screen and (min-width: 600px) {
            margin-left: 20px;
          }

          p {
            font-size: 0.875rem;
            position: relative;
            text-align: left;
            margin: 0 0 8px 20px;
            width: fit-content;

            @media only screen and (min-width: 600px) {
              margin: 0 0 8px;
            }

            &::before {
              content: '';
              position: absolute;
              display: inline-block;
              width: 13px;
              height: 13px;
              border-radius: 50%;
              left: -20px;
              background: var(--dark-blue);
              top: 50%;
              transform: translateY(-50%);
            }

            &.green {
              &::before {
                background: rgba(188, 234, 170, 1);
              }
            }
          }
        }
      }
    }

    .content-top-right {
      margin-top: 20px;
      position: absolute;
      top: 0;
      right: 40px;

      @media only screen and (min-width: 600px) {
        margin-top: 0;
      }
    }
  }

  .no-data {
    display: block;
    position: absolute;
    width: 90%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow-wrap: break-word;

    p {
      margin-bottom: 32px;
    }

    a.take-survey-button {
      display: inline-block;
    }
  }
}
