.breadcrumbs {
  margin-bottom: 32px;
  text-align: left;

  &__item {
    display: inline-block;
    margin-right: 5px;
    font-size: 1rem;
    color: var(--black);
    text-decoration: none;
    cursor: pointer;
  }
}
