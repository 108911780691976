.question-title {
  b,
  strong {
    color: var(--blue);
  }

  span {
    font-size: 1.125rem;
    display: block;
    width: 80%;
    margin: 0 auto;
    margin-top: 32px;
    font-weight: 300;

    strong {
      color: var(--black-gray);
    }
  }
}
