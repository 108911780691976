.content-container {
  .filter-button {
    --text-color: var(--black-gray);
    --background-color: var(--light-gray-blue);

    font-family: var(--font-family-semibold);
    font-size: 1rem;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 13px 20px 11px;
    margin: 0;
    cursor: pointer;
    box-shadow: none;
    color: var(--text-color);
    background-color: var(--white);
    transition: 0.3s ease-in-out;
    width: 100%;
    min-width: fit-content;
    border: 0;
    border-bottom: 1px solid var(--light-gray-darker);

    &:first-child {
      border-radius: 4px 4px 0 0;
    }

    &:last-child {
      border-radius: 0 0 4px 4px;
      border: 0;
    }

    @media only screen and (min-width: 1024px) {
      width: auto;
      justify-content: center;
      border: 0;
      border-right: 1px solid var(--light-gray-darker);

      &:first-child {
        border-radius: 4px 0 0 4px;
      }

      &:last-child {
        border-radius: 0 4px 4px 0;
        border: 0;
      }
    }

    &:hover,
    &--active {
      background-color: var(--black-gray);
      border-color: var(--black-gray);
      color: var(--white);
    }
  }
}
