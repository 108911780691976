.content-container {
  .overview__intro {
    &.no-border {
      border: none;
    }

    .logo-grid {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 80px 20px;
      justify-content: flex-start;
      width: 90%;
      max-width: 780px;
      margin: 0 auto 0 0;

      @media only screen and (min-width: 960px) {
        width: 100%;
        margin: 0 auto 0 0;
      }

      &__item {
        height: auto;
        max-height: 40px;
        width: calc((100% - 20px) / 2);
        display: flex;
        justify-content: center;

        @media only screen and (min-width: 570px) {
          width: calc((100% - 40px) / 3);
        }

        @media only screen and (min-width: 960px) {
          width: calc((100% - 60px) / 4);
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }
}
