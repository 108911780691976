.content-container {
  .upload-users {
    max-width: 700px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 0 auto;
    min-height: calc(100vh - 236px);
    padding: 50px 0;

    h1 {
      margin-bottom: 40px;
    }

    h3 {
      margin-top: 60px;
    }

    p {
      margin-bottom: 40px;
      line-height: 1.5;
    }

    .button {
      width: fit-content;
      margin: 0 auto;
    }

    .k-upload {
      border-radius: 34px;
      border: 1px solid var(--gray-blue);
      overflow: hidden;

      .k-dropzone {
        padding: 20px;

        .k-dropzone-hint {
          font-family: var(--font-family-bold);
          font-size: 1rem;
          text-align: center;
          color: var(--black-gray);
        }

        .k-button-solid-base {
          border-radius: 34px;
          color: var(--white);
          background-color: var(--black-gray);
          padding: 12px 24px;
          border: unset;
          transform: transistion(0.3s);
          font-family: var(--font-family-bold);

          &:hover {
            color: var(--black-gray);
            background-color: var(--gray-blue);
          }

          &:focus {
            box-shadow: unset;
          }
        }
      }
    }

    .k-file {
      padding: 20px 16px 40px;
    }

    .k-progressbar {
      bottom: 20px;
      width: calc(100% - 40px);
    }

    .k-actions {
      padding: 20px;
    }

    .k-button-solid-primary {
      border-radius: 40px;
      background-color: var(--black-gray);
      padding: 12px 24px;
      border: unset;
      transform: transistion(0.3s);
      font-family: var(--font-family-bold);

      &:hover {
        color: var(--black-gray);
        background-color: var(--white);
      }

      &:focus {
        box-shadow: unset;
      }
    }

    .k-button-solid-base {
      border-radius: 40px;
      color: var(--black-gray);
      background-color: var(--gray-blue);
      padding: 12px 24px;
      border: unset;
      transform: transistion(0.3s);
      font-family: var(--font-family-bold);

      &:hover {
        color: var(--black-gray);
        background-color: var(--white);
      }

      &:focus {
        box-shadow: unset;
      }
    }

    .step-by-styp-guide {
      button {
        margin-bottom: 60px;
      }

      .step {
        img {
          border-radius: 20px;
          width: 100%;
          box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
        }

        p {
          margin-bottom: 60px;
          padding: 0 20px;
        }
      }
    }
  }
}
