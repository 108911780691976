.content-container {
  .detailed-statistics {
    display: flex;
    gap: 60px 47px;
    flex-wrap: wrap;
    margin-bottom: 47px;
    position: relative;
    min-height: 100px;

    @media only screen and (min-width: 1024px) {
      gap: 120px 47px;
    }

    &--tno {
      @media only screen and (min-width: 1024px) {
        gap: 24px;
      }
    }
  }
}
